const circlesHelper = (total) => {
  const result = [...new Array(total)].reduce((acc, un, i) => {
    const curr = total - i
    const percent = (curr * 100) / total
    const reference = acc.length ? acc[acc.length - 1][2] : 100

    const result = (100 / reference) * percent
    acc.push([curr, result, percent])
    return acc
  }, [])

  return result.reverse()
}

export default circlesHelper
