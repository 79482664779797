import React, { memo, useCallback } from 'react'
import Recursive from 'components/Circles/Recursive'
import { Inner, BackgroundWrapper, Background as Bkground } from 'components/Ui'

const backgroundProps = (item) => ({
  counter: item[0],
  size: item[1],
})

export const Background = memo(({ children, levels }) => (
  <BackgroundWrapper>
    <Recursive layers={levels} component={Bkground} calcProps={backgroundProps} />
    {children}
  </BackgroundWrapper>
))

const stopProp = (func, arg) => (e) => {
  e.stopPropagation()
  func(arg)
}

export const InnerBackground = memo(({ onMouseOver, onClick, selected, levels }) => {
  const calcProps = useCallback(
    (item) => ({
      onClick: stopProp(onClick, item[0]),
      selected: selected === item[0],
      onMouseOver: stopProp(onMouseOver, item[0]),
      onBlur: stopProp(onMouseOver, 0),
      onFocus: stopProp(onMouseOver, item[0]),
      onKeyPress: (e) => e.key === 'Enter' && stopProp(onClick, item[0]),
      tabIndex: 0,
      size: item[1],
      'aria-label': `current value ${selected}, change it to ${item[0]}`,
      'aria-roledescription': 'button',
    }),
    [onClick, selected, onMouseOver]
  )

  return <Recursive layers={levels} component={Inner} calcProps={calcProps} />
})
