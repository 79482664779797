import React, { memo } from 'react'

const Recursive = memo(({ layers, component: Component, children, calcProps }) => {
  if (layers.length) {
    const [currentLayer, ...restLayers] = layers
    const nextChildren = <Component {...calcProps(currentLayer)}>{children}</Component>
    return (
      <Recursive layers={restLayers} calcProps={calcProps} component={Component}>
        {nextChildren}
      </Recursive>
    )
  }

  return children
})

export default Recursive
