import React, { memo } from 'react'
import { Provider } from 'react-redux'
import { AppFrame } from '@redthreads/components'
import { Wrapper } from 'components/Ui'
import Controllers from 'components/Controllers'

import Circles from 'components/Circles'
import store from 'store'
// import save from './utils/save'

const Description = () => (
  <>
    A <i>wheel of life</i>, for teams
  </>
)

export default memo(() => (
  <Provider store={store}>
    <AppFrame title="Training Wheel" description={<Description />}>
      <Wrapper>
        <Controllers />
        <Circles />
      </Wrapper>
    </AppFrame>
  </Provider>
))
