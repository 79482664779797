import { connect } from 'react-redux'
import { getContent, getCloudActivation } from 'store/selectors'
import { makeSelection, enableCloud } from 'store/action-creators'
import UiComponent from './Circles'

const mapStateToProps = (state) => ({
  wedges: getContent(state),
  cloud: getCloudActivation(state),
})

const mapDispatchToProps = {
  makeSelection,
  enableCloud,
}

export default connect(mapStateToProps, mapDispatchToProps)(UiComponent)
