import { connect } from 'react-redux'
import { getContent, getCloudActivation, getThemes } from 'store/selectors'
import { makeSelection, enableCloud, changeTheme } from 'store/action-creators'
import UiComponent from './Controllers'

const mapStateToProps = (state) => ({
  content: getContent(state),
  cloud: getCloudActivation(state),
  themes: getThemes(state),
})

const mapDispatchToProps = {
  makeSelection,
  enableCloud,
  changeTheme,
}

export default connect(mapStateToProps, mapDispatchToProps)(UiComponent)
