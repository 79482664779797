import React, { memo, useState, useCallback } from 'react'
import { Background, InnerBackground } from 'components/Circles/Background'
import { Wedge, WedgeTitle, CircleWrapper, CloudEnableWrapper } from 'components/Ui'
import { Cloud } from '@redthreads/components'

const LoopedArea = memo(
  ({ value, wedgeIdx, areaIdx, title, setTitle, makeSelection }) => {
    const [overValue, setOverValue] = useState(value)

    const onClick = useCallback(
      (value) => makeSelection(areaIdx, value),
      [makeSelection, areaIdx]
    )

    const onMouseOver = useCallback(
      (newValue) => {
        setOverValue(newValue)
        setTitle(title)
      },
      [setTitle, title]
    )

    const onMouseOut = useCallback(() => {
      setOverValue(value || 0)
    }, [value])

    return (
      <Wedge
        onMouseOut={onMouseOut}
        count={wedgeIdx * 2 + areaIdx - 1}
        value={overValue || value}
      >
        <InnerBackground onClick={onClick} selected={value} onMouseOver={onMouseOver} />
      </Wedge>
    )
  }
)

const LoopedWedge = memo(({ areas, wedgeIdx, makeSelection, setTitle }) => {
  const makeSelectionLocal = useCallback(
    (areaIdx, value) => makeSelection(wedgeIdx, areaIdx, value),
    [makeSelection, wedgeIdx]
  )

  return areas.map(({ title, value }, idx) => {
    return (
      <LoopedArea
        key={`${idx}-${value}`}
        wedgeIdx={wedgeIdx}
        areaIdx={idx}
        makeSelection={makeSelectionLocal}
        setTitle={setTitle}
        title={title}
        value={value}
      ></LoopedArea>
    )
  })
})

export default memo(({ wedges, makeSelection, cloud, enableCloud }) => {
  const [currentTitle, setTitle] = useState('')

  return (
    <CircleWrapper>
      <Background>
        {wedges.map(({ areas }, idx) => (
          <LoopedWedge
            key={idx}
            areas={areas}
            makeSelection={makeSelection}
            setTitle={setTitle}
            wedgeIdx={idx}
          />
        ))}
      </Background>
      <WedgeTitle>{currentTitle}</WedgeTitle>
      <CloudEnableWrapper>
        <Cloud vertical cloud={cloud} onCloudEnabled={enableCloud} />
      </CloudEnableWrapper>
    </CircleWrapper>
  )
})
