import { createReducers, stateHelper } from '@redthreads/redux-helpers'
import copy from './copy'
import constants, { levels, themes } from 'store/constants'
import circles from 'store/helpers/circles'

const addDefaultValues = (copy) =>
  copy.map((item) => {
    return stateHelper.mergeAll(item, {
      'areas.0.value': 0,
      'areas.1.value': 0,
    })
  })

const initialState = {
  cloud: false,
  levels: circles(levels),
  theme: 'purple',
  themes,
  content: addDefaultValues(copy),
}

const reducer = createReducers({
  [constants.CHANGE_THEME]: (state, { theme }) => ({
    ...state,
    theme,
  }),
  [constants.CLOUD]: (state) => ({
    ...state,
    cloud: true,
  }),
  [constants.SYNC]: (state, payload) => ({
    ...state,
    ...payload,
    cloud: true,
  }),
  [constants.MAKE_SELECTION]: (state, { wedge, area, value }) =>
    stateHelper.set(state, `content.${wedge}.areas.${area}.value`, value),
})

const dataReducer = (state = initialState, action) => reducer(state, action)

export default dataReducer
