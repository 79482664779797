import { actionObject } from '@redthreads/redux-helpers'
import constants from 'store/constants'

export const changeTheme = (theme) => actionObject(constants.CHANGE_THEME, { theme })
export const enableCloud = () => actionObject(constants.CLOUD)
export const makeSelection = (wedge, area, value) =>
  actionObject(constants.MAKE_SELECTION, {
    wedge,
    area,
    value,
  })
