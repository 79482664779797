import { connect } from 'react-redux'
import { getTheme, getTotal } from 'store/selectors'
import { Wedge as WedgeUi, Input as InputUi } from './Components'

export {
  Background,
  BackgroundWrapper,
  CircleWrapper,
  ControllerWrapper,
  EmojiWrap,
  Inner,
  WedgeTitle,
  Wrapper,
  ControllerTitle,
  CloudEnableWrapper,
  Label,
} from './Components'

export const Wedge = connect((state) => ({
  theme: getTheme(state),
  total: getTotal(state),
}))(WedgeUi)

export const Input = connect((state) => ({
  theme: getTheme(state),
}))(InputUi)
