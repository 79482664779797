import React, { useState, useCallback } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const W = styled.div`
  flex: 0 1 100%;
  @media (min-width: 701px) {
    max-width: 75%;
  }
`

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? data.color : null,
      color: isFocused ? 'white' : 'black',
      fontWeight: isSelected || isFocused ? 'bold' : 'normal',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : null),
      },
    }
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

const ThemeSelector = ({ themes, selected, onThemeChange }) => {
  const [options] = useState(() => {
    return Object.keys(themes)
      .sort()
      .reduce(
        (acc, theme) => [...acc, { value: theme, label: theme, color: themes[theme][1] }],
        []
      )
  }, [])

  const onChange = useCallback(
    ({ value }) => {
      onThemeChange(value)
    },
    [onThemeChange]
  )

  return (
    <W>
      <Select
        onChange={onChange}
        style={{ maxWidth: '80%' }}
        value={options.find(({ value }) => value === selected)}
        label="Select Theme"
        options={options}
        styles={colourStyles}
      />
    </W>
  )
}

export default ThemeSelector
