export const getCloudActivation = ({ data: { cloud } }) => cloud
export const getContent = ({ data: { content } }) => content
export const getLevels = ({ data: { levels } }) => levels
export const getThemes = ({ data: { themes, theme } }) => ({
  available: themes,
  selected: theme,
})
export const getTheme = ({ data: { theme, themes } }) => themes[theme]
export const getTotal = (state) =>
  getContent(state).reduce((count, item) => count + item.areas.length, 0)
