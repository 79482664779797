import React, { memo, useCallback } from 'react'
import ThemeSelector from './ThemeSelector'
import { ToolTip } from '@redthreads/components'
import {
  ControllerWrapper,
  Input,
  EmojiWrap,
  ControllerTitle,
  Label,
} from 'components/Ui'

const emojiSet = ['', '😐', '😬', '🙂', '😀', '😁']

const ShowAverage = ({ areas }) => {
  const values = areas.map(({ value }) => parseInt(value, 10)).filter((i) => !!i)
  const result =
    values.length < areas.length ? '' : values.reduce((a, b) => a + b, 0) / values.length

  return (
    <>
      <EmojiWrap>
        {result} {emojiSet[Math.floor(result)]}
      </EmojiWrap>
    </>
  )
}

export default memo(({ content, makeSelection, themes, changeTheme }) => {
  const select = useCallback(
    (e) => makeSelection(...e.target.name.split('-'), e.target.value),
    [makeSelection]
  )

  return (
    <>
      <ControllerWrapper>
        <ThemeSelector
          onThemeChange={changeTheme}
          themes={themes.available}
          selected={themes.selected}
        />

        {content.map(({ title, areas }, wedgeIdx) => (
          <div key={wedgeIdx}>
            <ControllerTitle>
              {title} <ShowAverage areas={content[wedgeIdx].areas} />
            </ControllerTitle>
            <ul>
              {areas.map(({ title, value, description }, areaIdx) => (
                <li key={title}>
                  <Label htmlFor={title}>
                    {title} <ToolTip description={description} />{' '}
                    <Input
                      id={title}
                      value={value}
                      type="range"
                      name={`${wedgeIdx}-${areaIdx}`}
                      onChange={select}
                      min={0}
                      max={5}
                    />
                  </Label>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </ControllerWrapper>
    </>
  )
})
