const copy = [
  {
    title: 'Energy',
    areas: [
      {
        title: 'Accountable & engaged',
        description: `Your perception of the team
          involvement with the project`,
      },
      {
        title: 'Drive & attitude',
        description: `Your perception of the team
          motivation and approach`,
      },
    ],
  },
  {
    title: 'Direction',
    areas: [
      {
        title: 'Common purpose and vision',
        description: `Your perception of the team
          clarity around the mission`,
      },
      {
        title: 'Monitor & measure progress',
        description: `Your perception of the team
          ability to keep track of progress`,
      },
    ],
  },
  {
    title: 'Knowledge',
    areas: [
      {
        title: 'Technical knowledge',
        description: `Your perception of the team understanding
          of the technical aspects of the project`,
      },
      {
        title: 'Skillful application / competence',
        description: `Your perception of the team ability of
          creating proper technical solutions`,
      },
    ],
  },
  {
    title: 'Relationship',
    areas: [
      {
        title: 'Value & trust each other',
        description: `Your perception of the team
          interpersonals relationships`,
      },
      {
        title: 'Feedback, challenge and learn',
        description: `Your perception of the team members ability
          to improve and learn from each other`,
      },
    ],
  },
]

export default copy
