import { connect } from 'react-redux'
import { getLevels } from 'store/selectors'
import {
  Background as BackgroundUi,
  InnerBackground as InnerBackgroundUi,
} from './Background'

export const Background = connect((state) => ({
  levels: getLevels(state),
}))(BackgroundUi)

export const InnerBackground = connect((state) => ({
  levels: getLevels(state),
}))(InnerBackgroundUi)
