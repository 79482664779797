import styled, { css } from 'styled-components'
import lightness from 'lightness'

export const Inner = styled.div`
  cursor: pointer;
  border: 0;
  position: absolute;
  left: 50%;
  outline: none;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(props) => 100 - props.size};
  width: ${(props) => `${props.size}%`};
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

const calcPath = (props) => {
  if (props.total === 12) return 21.1
  if (props.total === 16) return 29.3
  return 0
}

export const Wedge = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0.8;
  width: 100%;

  clip-path: polygon(
    100% ${(props) => calcPath(props)}%,
    100% ${(props) => (props.total > 4 ? 50 : 0)}%,
    50% 50%,
    50% 50%
  );

  transform: rotate(${(props) => props.count * (360 / props.total)}deg);

  ::before {
    height: 0;
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ::after {
    transform: scale(${(props) => props.value / 5});
    border: 1px solid
      ${(props) => lightness(props.theme[props.value - 1] || 'rgba(0,0,0,0)', -50)};
    position: absolute;
    top: 0%;
    left: 0%;
    content: '';
    display: block;
    background: ${(props) => props.theme[props.value - 1] || 'rgba(0,0,0,0)'};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
  }
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 75%;
  top: 3em;
  max-width: 500px;
  transform: translateX(-50%);
  margin-left: 50%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    margin-top: -3%;
    width: 1px;
    padding-bottom: 106%;
    height: 0;
    background: #555;
    z-index: 120;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    margin-top: 50%;
    left: -3%;
    width: 106%;
    height: 1px;
    background: #555;
    z-index: 120;
  }
`

export const Background = styled.div`
  position: absolute;
  left: 50%;
  z-index: ${(props) => 100 - props.size};
  margin-top: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => `${props.size}%`};
  background: ${(props) => lightness('#aaa', props.counter * 5)};
  border-radius: 100%;

  &:before {
    display: block;
    content: '${(props) => props.counter}';
    position: absolute;
    left: calc(50% + 5px);
    top: -${(props) => Math.floor(props.size / 30)}%;
    font-size: 12px;
    font-weight: bold;

    @media screen and (min-width: 25em) {
      font-size: calc(16px + (24 - 16) * (100vw - 400px) / (800 - 400));
    }
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

export const CircleWrapper = styled.div`
  width: 60%;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    @media (max-width: 700px) {
      padding-top: 100%;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const ControllerWrapper = styled.div`
  min-width: 25%;
  font-size: 80%;
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
  }
  > div {
    min-width: 48%;
  }
  @media (max-width: 700px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export const WedgeTitle = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: pre;
  top: 0;
`

export const Wrapper = styled.div`
  display: flex;
  padding: 0 0 10px 15px;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
`

const sliderThumb = css`
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E');
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  width: 22px;
`

export const Label = styled.label`
  display: block;
  font-size: 110%;
`

export const Input = styled.input`
  &[type='range'] {
    appearance: none;
    background: #eef1f6;
    border-radius: 3px;
    height: 6px;
    width: 75%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &[type='range']::-webkit-slider-thumb {
    ${sliderThumb};
    background-color: ${(props) => props.theme[props.value - 1] || lightness('#aaa', 10)};
  }
  &[type='range']::-moz-range-thumb {
    ${sliderThumb};
    background-color: ${(props) => props.theme[props.value - 1] || lightness('#aaa', 10)};
  }
  &[type='range']::-ms-thumb {
    ${sliderThumb};
    background-color: ${(props) => props.theme[props.value - 1] || lightness('#aaa', 10)};
  }
  &[type='range']::-moz-focus-outer {
    border: 0;
  }
`

export const EmojiWrap = styled.span`
  font-size: 1.5rem;
  z-index: -1;
  position: absolute;
  display: block;
  bottom: -4px;
  border-bottom: 1px dashed #ccc;
  width: 100%;
  text-align: right;
`

export const ControllerTitle = styled.h3`
  position: relative;
  font-size: 120%;
`

export const CloudEnableWrapper = styled.div`
  @media (min-width: 701px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 700px) {
    margin: -1.5rem auto 1em;
  }
`
