import { keys } from '@redthreads/redux-helpers'

export const levels = 5

export const themes = {
  'traffic light': [
    // https://www.schemecolor.com/traditional-fall.php
    'rgb(178, 31, 1)',
    'rgb(222, 112, 15)',
    // https://www.schemecolor.com/traffic-red-yellow-green.php
    'rgb(239, 183, 0)',
    'rgb(115, 157, 40)',
    'rgb(0, 132, 80)',
  ],
  fire: [
    // https://www.schemecolor.com/fire-factory.php
    'rgb(97, 28, 7)',
    'rgb(145, 0, 0)',
    'rgb(178, 34, 34)',
    'rgb(237, 129, 17)',
    'rgb(255, 187, 0)',
  ],
  ocean: [
    // https://www.schemecolor.com/ocean-waves-of-blue.php
    'rgb(29, 83, 145)',
    'rgb(0, 119, 188)',
    'rgb(29, 163, 184)',
    'rgb(80, 191, 161)',
    'rgb(103, 219, 188)',
  ],
  purple: [
    // https://www.schemecolor.com/violet-pink-gradient.php
    'rgb(54, 1, 103)',
    'rgb(107, 7, 114)',
    'rgb(175, 18, 129)',
    'rgb(207, 38, 138)',
    'rgb(230, 92, 156)',
  ],
  valentine: [
    // https://www.schemecolor.com/traditional-valentine.php
    'rgb(79, 24, 102)',
    'rgb(156, 22, 71)',
    'rgb(194, 30, 87)',
    'rgb(232, 53, 53)',
    'rgb(252, 194, 0)',
  ],
  monochrome: [
    // https://www.schemecolor.com/traditional-valentine.php
    'rgb(40, 40, 40)',
    'rgb(80, 80, 80)',
    'rgb(120, 120, 120)',
    'rgb(160, 160, 160)',
    'rgb(200, 200, 200)',
  ],
}

export default keys(['MAKE_SELECTION', 'CLOUD', 'SYNC', 'CHANGE_THEME'])
